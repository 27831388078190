import { OrderModel } from 'lib/model/objects/orderModel';
import { useEffect, useState } from 'react';
import { ConsolidatedOrderPricing } from 'lib/types/order';
import { logAndCaptureException } from 'utils';
import { removeUndefinedFields } from 'lib/helpers';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import { safeStringify } from 'lib/utils/stringify';
import { ColumnService } from 'lib/services/directory';
import { validateCompleteNewspaperOrder } from 'lib/types/newspaperOrder';
import { CollectionKey, SnapshotModel } from 'lib/model';
import { ModelObject } from 'lib/model/types';
import { updateOrderPricing } from '../helpers/orderOperations';
import {
  NewspaperOrdersFormData,
  OrderFormData
} from '../PlacementFlowStepSelector';
import { hashString } from '../helpers/crypt';

const RECALCULATE_PRICE_DEBOUNCE_MS = 1000;

type usePricingProps<T extends ModelObject, U extends CollectionKey> = {
  orderModel: OrderModel | undefined;
  newspaperOrdersFormData: NewspaperOrdersFormData;
  adFormData: Partial<T>;
  adModel: SnapshotModel<T, U>;
  orderFormData: OrderFormData;
  version: number;
};

export const usePricing = <T extends ModelObject, U extends CollectionKey>({
  newspaperOrdersFormData,
  orderModel,
  adFormData,
  adModel,
  orderFormData,
  version
}: usePricingProps<T, U>) => {
  const [consolidatedOrderPricing, setConsolidatedOrderPricing] =
    useState<ConsolidatedOrderPricing>();
  const [priceIsStale, setPriceIsStale] = useState(true);
  const [orderDataCacheKey, setOrderDataCacheKey] = useState('');
  const [priceLoading, setPriceLoading] = useState(false);
  const [error, setError] = useState('');

  // We're returning the price here to get it to callers immediately so they don't need to wait for state change
  const handleRefreshOrderPrice = async (): Promise<
    ConsolidatedOrderPricing | undefined
  > => {
    setPriceLoading(true);
    if (!orderModel) {
      return;
    }
    await orderModel.refreshData();
    const consolidatedPricing = await orderModel.getConsolidatedPricing({
      distributeFee: true,
      version
    });
    setConsolidatedOrderPricing(consolidatedPricing);
    setPriceLoading(false);
    return consolidatedPricing;
  };

  // We're returning the price here to get it to callers immediately so they don't need to wait for state change
  const handleRecalculateOrderPrice = async (): Promise<
    ConsolidatedOrderPricing | undefined
  > => {
    if (!orderModel) {
      return;
    }
    try {
      setError('');
      setPriceLoading(true);
      await adModel.ref.update(removeUndefinedFields(adFormData));
      await orderModel.ref.update(removeUndefinedFields(orderFormData));
      await orderModel.updateNewspaperOrders(
        removeUndefinedFields(newspaperOrdersFormData),
        version
      );
      await updateOrderPricing(orderModel.id, version);
      const consolidatedPricing = await handleRefreshOrderPrice();
      setPriceIsStale(false);
      return consolidatedPricing;
    } catch (e) {
      setError('Failed to recalculate order price.  Please try again later.');
      logAndCaptureException(
        ColumnService.ORDER_PLACEMENT,
        e,
        'Failed to recalculate order price',
        {
          orderId: orderModel.id
        }
      );
    }
  };

  /**
   * Update the priceIsStale state when the form data changes and set a "cache key" that will be used to debounce
   * the recalculation of the order price.
   * We re-calculte the cache key whenever we change text, images or our layout.
   */
  useEffect(() => {
    if (!priceIsStale) {
      setPriceIsStale(true);
    }
    void (async () => {
      if (newspaperOrdersFormData.length === 0) {
        return;
      }
      for (const newspaperOrder of newspaperOrdersFormData) {
        if (!validateCompleteNewspaperOrder(newspaperOrder)) {
          return;
        }
      }
      const orderCacheKey =
        (await hashString(safeStringify(newspaperOrdersFormData) || '')) +
        (await hashString(safeStringify(adFormData) || ''));
      setOrderDataCacheKey(orderCacheKey);
    })();
  }, [
    safeStringify(newspaperOrdersFormData),
    safeStringify(adFormData),
    adFormData.filingTypeName
  ]);

  const debouncedCacheKey = useDebounce(
    orderDataCacheKey,
    RECALCULATE_PRICE_DEBOUNCE_MS
  );
  useEffect(() => {
    if (!debouncedCacheKey) {
      return;
    }
    void handleRecalculateOrderPrice();
  }, [orderModel?.id, debouncedCacheKey]);

  return {
    priceIsStale,
    consolidatedOrderPricing,
    priceLoading,
    handleRecalculateOrderPrice,
    handleRefreshOrderPrice,
    priceError: error
  };
};
