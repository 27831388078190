import { ESnapshot, EOrganization, EUser, exists } from 'lib/types';
import { canPublisherUserSeeNewspaperSelect } from 'sagas/helpers';
import { getSubdomain } from 'utils/urls';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { OrgContextState } from 'lib/types/organization';
import { getDynamicRoute } from 'lib/frontend/utils/router';
import { NOTICE_ROUTES } from 'router/routes';
import { isAnonymousFlow } from '.';

export const CONFIRM_PUBLISHER = 'newspaper-select';
export const CONFIRM_AD = 'confirm-ad';
export const CONFIRM_FILER = 'confirm-filer';
export const MAILED_AFFIDAVIT = 'mailed-affidavit';
export const SCHEDULE_NOTICE = 'schedule-notice';
export const CONFIRM_PROOF = 'confirm-proof';
export const INVOICE_RECIPIENT = 'invoice-recipient';
export const BIDDY_PLANROOM = 'biddy-planroom';
export const NOTICE_BY_MAIL = 'notice-by-mail';

export const STEP_IDS_TO_TITLES: Record<string, string> = {
  [CONFIRM_PUBLISHER]: 'Select Publisher',
  [CONFIRM_FILER]: 'Provide Customer Information',
  [CONFIRM_AD]: 'Create Notice',
  [SCHEDULE_NOTICE]: 'Schedule Notice'
};

export type StepEntry = {
  id: string;
  next?: string;
  previous?: string;
};

type StepConfig = {
  id: string;
  visible: boolean;
};

type PlacementStepOrderParams = {
  user: ESnapshot<EUser> | null;
  isUserLoggedIn: boolean;
  isPublisher: boolean;
  orgContext: OrgContextState;
  isEditing: boolean;
  showAccountInfoStep: boolean;
};

type PlacementStepsMap = Record<string, StepEntry>;

export const calculatePlacementSteps = ({
  user,
  isUserLoggedIn,
  isPublisher,
  orgContext,
  isEditing,
  showAccountInfoStep
}: PlacementStepOrderParams): StepConfig[] => {
  if (!isUserLoggedIn) return [];
  const onSubDomain = getSubdomain() === orgContext?.subdomain;

  const placementStepsConfig = [
    {
      id: CONFIRM_PUBLISHER,
      visible: isPublisher
        ? exists(user) && !!canPublisherUserSeeNewspaperSelect(user, !isEditing)
        : !onSubDomain && !isEditing
    },
    {
      id: CONFIRM_FILER,
      visible:
        !isEditing &&
        (isPublisher ||
          shouldShowConfirmFilerForAdvertiser(user, showAccountInfoStep))
    },
    {
      id: CONFIRM_AD,
      visible: true
    },
    {
      id: SCHEDULE_NOTICE,
      visible: true
    },
    {
      id: CONFIRM_PROOF,
      visible: true
    }
  ];

  return placementStepsConfig;
};

export function getFormattedPlacementSteps(placementStepsConfig: StepConfig[]) {
  const placementStepOrder = placementStepsConfig
    .filter(step => step.visible)
    .map(step => step.id);

  const placementStepsMap: Record<string, StepEntry> = {};

  placementStepOrder.forEach((stepId, index) => {
    placementStepsMap[stepId] = {
      id: stepId,
      next: placementStepOrder[index + 1],
      previous: placementStepOrder[index - 1]
    };

    if (stepId === CONFIRM_PROOF) {
      placementStepsMap[stepId].previous = placementStepOrder[index - 2];
    }
  });

  return placementStepsMap;
}

export function getVisiblePlacementSteps(placementStepsConfig: StepConfig[]) {
  return (
    placementStepsConfig
      // filter for steps that actually appear in placement
      .filter(stepConfig => stepConfig.visible)
      // get an array of step ids
      .map(stepConfig => stepConfig.id)
  );
}

function shouldShowConfirmFilerForAdvertiser(
  user: ESnapshot<EUser> | null,
  showAccountInfoStep: boolean
) {
  const startedFromAnonymousFlow =
    sessionStorage.getItem('startedFromAnonymousFlow') === 'true';

  const enableNewPlacementFlow = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW
  );

  const advertiserHasMultipleOrgs =
    exists(user) && (user.data().allowedOrganizations?.length || 0) > 1;

  const publisherHasAccountNumberField =
    enableNewPlacementFlow && showAccountInfoStep;

  return (
    isAnonymousFlow() ||
    startedFromAnonymousFlow ||
    advertiserHasMultipleOrgs ||
    publisherHasAccountNumberField
  );
}

export function doesSelectedNewspaperHaveNoticeTypes(
  newspaper: ESnapshot<EOrganization> | undefined,
  orgContext: OrgContextState
) {
  const allowedNotices = exists(newspaper)
    ? newspaper.data().allowedNotices
    : orgContext?.allowedNotices;
  return (
    allowedNotices &&
    !!allowedNotices.filter(noticeType => !noticeType.disabled).length
  );
}

/**
 * Get the first step in the placement flow
 */
export function getFirstStep({
  placementStepsMap,
  urlStep
}: {
  placementStepsMap: PlacementStepsMap;
  urlStep: string | null;
}): StepEntry | undefined {
  if (urlStep && placementStepsMap[urlStep]) {
    return placementStepsMap[urlStep];
  }

  const firstStepId = Object.keys(placementStepsMap).find(
    key => !placementStepsMap[key].previous
  );
  return placementStepsMap[firstStepId || ''];
}

const PLACEMENT_STEPS = [
  CONFIRM_PUBLISHER,
  CONFIRM_AD,
  CONFIRM_FILER,
  MAILED_AFFIDAVIT,
  SCHEDULE_NOTICE,
  CONFIRM_PROOF,
  INVOICE_RECIPIENT,
  BIDDY_PLANROOM,
  NOTICE_BY_MAIL
] as const;
type PlacementStep = (typeof PLACEMENT_STEPS)[number];
type NoticePlacementUrlOptions = {
  step?: PlacementStep;
  duplicate?: boolean;
};
export function getNoticePlacementUrl(
  id: string,
  { step, duplicate }: NoticePlacementUrlOptions = {}
) {
  const url = getDynamicRoute(NOTICE_ROUTES.PLACE, { id });

  const params = new URLSearchParams();

  if (step) {
    params.append('step', step);
  }

  if (duplicate) {
    params.append('duplicate', 'true');
  }

  return `${url}?${params.toString()}`;
}
