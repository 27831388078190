import { ESnapshotExists, EOrganization, EUser } from '.';

type FeatureFlagOptionsBase<FlagType> = {
  defaultValue: FlagType;
};

/**
 * Subset of a LaunchDarkly user object.
 */
export interface CustomLDUser {
  /**
   * A unique string identifying a user.
   */
  key: string;

  /**
   * Any additional attributes associated with the user.
   */
  custom?: {
    [key: string]: string | boolean | number | Array<string | boolean | number>;
  };
}

type CustomFeatureFlagOptions<FlagType> = FeatureFlagOptionsBase<FlagType> & {
  type: 'custom';
  user: CustomLDUser;
};

type NoSnapshotFeatureFlagOptions<FlagType> =
  FeatureFlagOptionsBase<FlagType> & {
    type: 'anonymous';
  };

// Used for percentage-based rollouts in the BE where we need each request to have a unique key
type RandomizedKeyFeatureFlagOptions<FlagType> =
  FeatureFlagOptionsBase<FlagType> & {
    type: 'randomized';
  };

type UserFeatureFlagOptions<FlagType> = FeatureFlagOptionsBase<FlagType> & {
  type: 'user';
  snapshot: ESnapshotExists<EUser>;
};

type OrganizationFeatureFlagOptions<FlagType> =
  FeatureFlagOptionsBase<FlagType> & {
    type: 'organization';
    snapshot: ESnapshotExists<EOrganization>;
  };

export type FeatureFlagOptions<FlagType> =
  | NoSnapshotFeatureFlagOptions<FlagType>
  | UserFeatureFlagOptions<FlagType>
  | OrganizationFeatureFlagOptions<FlagType>
  | RandomizedKeyFeatureFlagOptions<FlagType>
  | CustomFeatureFlagOptions<FlagType>;

export enum LaunchDarklyFlags {
  /*
   * A kill switch for all AA notifications
   * */
  ALLOW_AUTOMATED_AFFIDAVIT_NOTIFICATIONS = 'allow-automated-affidavit-notifications',

  /**
   * Pause the entire Dwolla payouts pipeline. This is intended only as an
   * emergency kill switch if we are having payments isues.
   */
  DISABLE_DWOLLA_PAYOUTS = 'disable-dwolla-payouts',

  /**
   * Pause the monthly bulk invoice generation pipeline. This is intended only as an
   * emergency kill switch if we are having payments isues.
   */
  DISABLE_BULK_INVOICE_SCHEDULER = 'disable-bulk-invoice-scheduler',

  /**
   * Kill switch to pause affidavit reminders.
   * This is intended as a temporary flag until ONCALL-1133 gets resolved
   */
  DISABLE_AFFIDAVIT_REMINDER_NOTIFICATIONS = 'disable-affidavit-reminder-notifications',

  /**
   * Kill switch to pause invoice upfront payment required reminders.
   * This is intended as a temporary flag until ONCALL-1133 gets resolved
   */
  DISABLE_INVOICE_REMINDER_NOTIFICATIONS = 'disable-invoice-reminder-notifications',

  /**
   * Allow multi-org publishers to select the placement paper
   * See COREDEV-950
   * @deprecated
   */
  ENABLE_NEWSPAPER_SELECTION_FOR_PUBLISHERS = 'enable-newspaper-selection-for-publishers',

  /**
   * Enable bank account verification via Stripe UI instead of Plaid
   * See COREDEV_469
   */
  ENABLE_STRIPE_UI_BANK_ACCOUNT_VERIFICATION = 'enable-stripe-ui-bank-account-verification',

  /**
   * For auto-invoiced notices, allow invoices to be regenerated after edits.
   * See COREDEV-1669
   */
  ENABLE_AUTO_INVOICE_AFTER_EDITS = 'enable-auto-invoice-after-edits',

  /**
   * Enables new hyphenation settings for long sequences in notice content.
   * See: IT-4401 and ONCALL-1530
   */
  ENABLE_ADVANCED_HYPHENATION = 'enable-advanced-hyphenation',

  /**
   * Test feature flag.
   */
  TEST_FEATURE = 'test-feature',

  /**
   * This flag determines whether the "Upload E-Edition" button will be visible to users
   * See COREDEV-1116
   */
  SHOW_E_EDITION_UPLOADER = 'show-e-edition-uploader',

  /**
   * @deprecated Enables indexing customer documents to Elastic
   */
  ENABLE_SYNC_CUSTOMERS_TO_ELASTIC = 'enable-sync-customers-to-elastic',

  /**
   * Enables whether or not a publisher can edit the order number for an invoice
   */
  ENABLE_ORDER_NUMBER_EDITING = 'enable-order-number-editing',

  /**
   * Will allow for bulk pagination without ever hitting the Indesign server
   */
  ENABLE_TURBO_BULK_PAGINATION = 'enable-turbo-bulk-pagination',

  /**
   * Used for column express, where we redirect users through a typeform
   * to enter their newspaper information and proceed to file notices on their behalf
   * This will be toggled off (Feb 23) until we restructure Column Express
   */
  ENABLE_CANT_FIND_NEWSPAPER = 'enable-cant-find-newspaper',

  /**
   * @deprecated this flag was replaced by another one.
   */
  COREDEV_1166_HANDLE_TRANSFERS_AFTER_REFUNDS = 'coredev-1166-handle-transfers-after-refunds',

  /**
   * Determines whether we use the new integration gateway/script for a given publisher.
   */
  ENABLE_EVENT_DRIVEN_INTEGRATION = 'enable-event-driven-integration',

  /**
   * @deprecated this experiment succeeded and the flag is no longer used.
   */
  ENABLE_MADLIBS_EDITOR = 'enable-madlibs-editor',

  /**
   * @deprecated this experiment succeeded and the flag is no longer used.
   */
  ENABLE_MADLIBS_CONFIG = 'enable-madlibs-config',

  /**
   * @deprecated determines what triggers integrations that sync at deadline.
   */
  ENABLE_DEADLINE_SYNCS_BASED_ON_EVENT_CREATION = 'enable-deadline-syncs-based-on-event-creation',

  /**
   * @deprecated this experiment succeeded and the flag is no longer used.
   */
  ENABLE_SHOW_REPORTS_TAB = 'enable-show-reports-tab',

  /**
   * @deprecated this experiment succeeded and the flag is no longer used.
   */
  ENABLE_MORE_ACTIONS_BUTTON = 'enable-more-actions-button',

  /**
   * @deprecated this experiment succeeded and the flag is no longer used.
   */
  ENABLE_TEMPLATED_NOTICE_EDITOR = 'enable-templated-notice-editor',

  /**
   * @deprecated this experiment succeeded and the flag is no longer used.
   */
  ENABLE_NOTICE_TABLE_FILTERS = 'enable-notice-table-filters',

  /**
   * We want to test sending PDFs through the Seaton integration, and this toggle will make it easy
   * to switch between PDF- and text-based methods while testing.
   */
  ENABLE_PDF_AD_COMPOSITION_FOR_SEATON_INTEGRATION = 'toggle-ad-composition-for-seaton-integration',

  /**
   * Enable the new version of the placement flow
   */
  ENABLE_NEW_PLACEMENT_FLOW = 'enable-new-placement-flow',

  /*
   * Numeric feature flag. Returns number 0-100
   */
  INDESIGN_ERROR_SAMPLE_RATE = 'indesign-error-sample-rate',

  /**
   * @deprecated Enable the new version of the rates settings page
   */
  ENABLE_NEW_RATES_SETTINGS = 'new-rates-settings',

  /*
   * See APP-848
   */
  ENABLE_ADD_ORGS_FROM_CUSTOMER_LIST = 'enable-add-orgs-from-customer-list',

  /**
   * @deprecated this experiment succeeded and the flag is no longer used.
   */
  ENABLE_LOGOS_IN_LINERS = 'enable-logos-in-liners',

  /**
   * Enable Account ID management in the placement flow
   */
  ENABLE_ACCOUNT_ID_IN_PLACEMENT = 'enable-account-id-in-placement',

  /**
   * Disable migrations (kill switch)
   */
  DISABLE_MIGRATIONS = 'disable-migrations',

  /**
   * Use Lee's production Adpoint
   */
  USE_LEE_PRODUCTION_ADPOINT = 'use-lee-production-adpoint',

  /**
   * PERMANENT FLAG for overriding signing info.
   */
  NOTARIZE_WHITE_TEXT_OVERRIDES = 'notarize-white-text-overrides',

  /**
   * Shows subscriptions for government publishers in the header bar
   */
  SHOW_SUBSCRIPTION_IN_HEADER = 'show-subscription-in-header',

  /**
   * Enable the new version of our application haeder
   */
  NEW_LAYOUT_FRAME = 'enable-new-layout-frame',

  /**
   * Enable notice type editing in-app
   */
  ENABLE_NOTICE_TYPE_SETTINGS = 'enable-notice-type-settings',

  /**
  /*
   * Enable notice type editing in-app
   */
  PREVENT_NON_GOVERNMENT_CHECK_RECONCILIATION = 'prevent-non-government-check-reconciliation',

  /**
   * @deprecated this experiment succeeded and the flag is no longer used.
   */
  ENABLE_BULK_INVOICE_V2_TABLE = 'enable-bulk-invoice-v2-table',

  /**
   * @deprecated this experiment succeeded and the flag is no longer used.
   */
  REDIRECT_EXAVAULT_TO_FILEMAGE = 'redirect-exavault-to-filemage',

  /**
   * When turned on, Champion AMP ads clear (are not set on) setaside
   */
  ENABLE_CHAMPION_ADS_CLEAR_SETASIDE = 'enable_champion_ads_clear_setaside',

  /**
   * @deprecated Show the sync column in the Your Notices tbale
   */
  ENABLE_SYNC_STATUS_IN_NOTICE_TABLE = 'enable-sync-status-in-notice-table',

  /**
   * When turned on, we route to a new and improved invoice creation screen
   */
  ENABLE_NEW_CREATE_INVOICE_SCREEN = 'enable-new-create-invoice-screen',

  /**
   * When turned on, add a buffer to the newspaper deadline in notice placement flow
   */
  ENABLE_NOTICE_PLACEMENT_DEADLINE_BUFFER = 'enable-notice-placement-deadline-buffer',

  /**
   * When turned on, deadline settings will use new components
   * @deprecated This flag was used to toggle on updated deadline settings.
   */
  ENABLE_NEW_DEADLINES_SETTINGS = 'enable-new-deadlines-settings',

  /**
   * When turned on, the sync error table will be available and the icon will appear in the app header
   */
  ENABLE_SYNC_ERROR_TABLE = 'enable-sync-error-table',

  /**
   * When turned on, display site and archived tab in newspaper settings will use Elastic to query public notices
   * @deprecated
   */
  ENABLE_QUERYING_PUBLIC_NOTICES_FROM_ELASTIC = 'enable-querying-public-notices-from-elastic',

  /**
   * When turned on, the invoices route will be available to non bulk invoicing users and will show all invoices
   * @deprecated
   */
  SHOW_ALL_INVOICES_IN_INVOICES_TABLE = 'show-all-invoices-in-invoices-table',

  /**
   * @deprecated This flag was used to send a monthly report of unpaid invoices to the org/user
   */
  ENABLE_MONTHLY_UNPAID_INVOICES_REPORT = 'enable-monthly-unpaid-invoices-report',

  /**
   * When turned on, invoices table will use Elastic to query invoices
   * @deprecated This flag was used to toggle on updated deadline settings.
   */
  ENABLE_QUERYING_INVOICES_FROM_ELASTIC = 'enable-querying-invoices-from-elastic',

  /**
   * Show the DisplayAdActions card on the notice details page
   */
  ENABLE_DISPLAY_AD_ACTIONS_CARD = 'enable-display-ad-actions-card',

  /**
   * Show modular size options in the placement flow for display ads
   */
  ENABLE_MODULAR_SIZES = 'enable-modular-sizes',

  /**
   * When turned on, SCNG's integration will use the common MNG XML builder
   */
  SCNG_USE_MNG_XML_BUILDER = 'scng-use-mng-xml-builder',

  /**
   * When turned on, SCNG's integration will be events-based
   */
  USE_EVENTS_BASED_FOR_SCNG = 'use-events-based-for-scng',

  /**
   * When turned on, show toggle in template settings to enable/disble customised headers in a notice
   */
  ENABLE_CUSTOMISED_HEADERS = 'enable-customised-headers',

  /**
   * Show the new affidavit table in settings
   */
  ENABLE_AFFIDAVIT_SETTINGS_TABLE = 'enable-affidavit-settings-table',

  /*
   * When set to true, will attempt to extract text from e-editions using only
   * OCR, and will try to extract text directly from the e-edition as a non-rasterized
   * PDF only if OCR fails. When set to false, will always run both OCR and read the
   * non-rasterized text and concatenate the results.
   */
  OCR_FIRST_E_EDITION_TEXT_EXTRACTION = 'ocr-first-e-edition-text-extraction',

  /**
   * Enable using CloudConvert instead of the Indesign Server for bulk pagination RTF
   */
  ENABLE_BULK_RTF_CLOUDCONVERT = 'enable-bulk-rtf-cloud-convert',

  /**
   * @deprecated for enabling TBT merchant account attribution in Payway card transactions.
   * If false, we default to using the test TBT Payway account.
   */
  ENABLE_TBT_PROD_PAYWAY = 'enable-tbt-prod-payway',

  /**
   * Enable ability to add notes to notices on the notice details page
   */
  ENABLE_NOTICE_NOTES = 'enable-notice-notes',

  /*
   * @deprecated Used for managing Lee pagination
   */
  ENABLE_PAGINATION_TABLE = 'enable-pagination-table',

  /*
   * Used to limit indesign calls during AA
   */
  SKIP_INDESIGN_FOR_AFFIDAVIT_GENERATION = 'skip_indesign_for_affidavit_generation',

  /**
   * @deprecated Backend-only flag that will allow for the creation & maintenance of publicationIssues for papers that do not use pagination.
   */
  ENABLE_PUBLICATION_ISSUES_OUTSIDE_PAGINATION = 'enable-publication-issues-outside-pagination',

  /**
   * Enable pulling verification data from the new runs-based schema rather than events
   * @deprecated
   */
  USE_RUNS_BASED_VERIFICATION_SCHEMA = 'use-runs-based-verification-schema',

  /**
   * Enable the new e-edition infrastrucutre using processable events for e-edition uploads
   * @deprecated
   */
  USE_CRON_BASED_EEDITION_PROCESSOR = 'use-cron-based-eedition-processor',

  /** Enable the user to put the notice in a "Reviewing" status */
  ENABLE_AWAITING_CONFIRMATION_STATUS = 'enable-awaiting-confirmation-status',

  /**
   * Show in-app docs rendered via Markdown
   */
  ENABLE_IN_APP_DOCS = 'enable-in-app-docs',

  /**
   * Enable ability to put customers on 30 days end of month billing
   * @deprecated
   */
  ENABLE_THIRTY_DAYS_END_OF_MONTH_BILLING = 'enable-thirty-days-end-of-month-billing',

  /**
   * TEMPORARY FLAG for enabling SDUT merchant account attribution in Payway card transactions.
   * If false, we default to using the test SDUT Payway account.
   */
  ENABLE_SDUT_PROD_PAYWAY = 'enable-sdut-prod-payway',

  /**
   * When enabled, confirmed Champion AMP ads (whether paid or unpaid) will sync (whether or not such ads have synced before)
   * when manually requested by W Sorrells (Champion admin) for notices within a certain period.
   */
  ENABLE_CHAMPION_MONTHS_END_PAYMENT_SYNCS = 'enable_champion_months_end_payment_syncs',

  /**
   * When turned on, enable the new advertiser onboarding flow
   */
  ADVERTISER_ONBOARDING_SELFSERVE = 'advertiser-onboarding-selfserve',

  /**
   * Enable the new version of the partial refunds flow
   */
  ENABLE_PARTIAL_REFUNDS_V_2 = 'enable-partial-refunds-v-2',

  /**
   * Controls which model we are using for our automated support drafting
   */
  SUPPORT_ASSISTANT_MODEL = 'support-assistant-model',

  /**
   * Enable in-app pagination review
   */
  ENABLE_IN_APP_PAGINATION_REVIEW = 'enable-in-app-pagination-review',

  /**
   * When enabled, TBT and Fort Wayne Adbase integrations can (if further configured) send the <Documents/> tag (as requested by TBT)
   */
  ENABLE_ADBASE_DOCUMENT_TAG = 'enable-Adbase-document-tag', // Capitalization of 'Adbase' is required

  /**
   * When enabled, will re-size unsquashed tables in the bulk download file to fit the columns in the template.
   * Currently behind a flag as the method to do that was written specifically for Forum and needs further testing
   * before we're comfortable rolling out to all publishers.
   */
  ADJUST_TABLE_WIDTHS_IN_BULK_DOWNLOAD_FILE = 'adjust-table-widths-in-bulk-download-file',

  /**
   * When enabled, Champion payments on billable ads will sync to Champion AMP when synced
   */
  ENABLE_CHAMPION_SYNC_PAYMENTS_FOR_BILLABLE_ADS = 'enable-champion-sync-payments-for-billable-ads',

  /**
   * An experimental flag which keeps <table> tags in TinyMCE editor
   */
  ENABLE_TABLE_PASTING_IN_NOTICE_EDITOR = 'enable-table-pasting-in-notice-editor',

  /**
   * @deprecated Given a hash of an affidavit HTML template, returns whether or not we should use the updated
   * affidavit generation process.
   */
  ENABLE_PDFKIT_AFFIDAVIT_GENERATION = 'enable-pdfkit-affidavit-generation',

  /**
   * @deprecated When enabled, allowing reps to copy notice details on the Notice Details page
   */
  ENABLE_COPY_NOTICE_DETAILS_FOR_REPS = 'enable-copy-notice-details-for-reps',

  /**
   * Allows users to see the TinyMCE html 'code'
   */
  ENABLE_TINY_MCE_SOURCE_CODE = 'enable-tiny-mce-source-code',

  /**
   * When enabled, PDF document to text conversion in the palcement flow will
   * use cloud convert (pdf to docx to html) instead of OCR
   */
  ENABLE_PDF_TO_HTML_CLOUD_CONVERT = 'enable-pdf-to-html-cloud-convert',

  /**
   * Enable self-serve push in our placement inbox from front automations
   */
  ENABLE_SELF_SERVE_PUSH = 'enable-self-serve-push',

  /**
   * When enabled, stop auto-increase column width of notice and instead show user a message to increase
   * column width to fit the content.
   */
  STOP_AUTO_INCREASE_COLUMN_WIDTH = 'stop-auto-increase-column-width',

  /**
   * When enabled, we don't send the <AMPACCTNBR/> tag in the XML. This was causing (purportedly) duplication of ads in Champion for TL but not the other papers
   */
  CHAMPION_WITHHOLD_AMPACCTNBR_FROM_XML = 'champion-withhold-ampacctnbr-from-xml',

  /**
   * @deprecated The associated cron has been removed and this flag is no longer used.
   */
  E_EDITION_OCR_SUCCESS_EVENT_PROCESSOR_CONFIG = 'e-edition-ocr-success-event-processor-config',

  /**
   * Can be used to override the default processor config for the processEEditionUploadedEventsCron
   */
  E_EDITION_UPLOADED_EVENT_PROCESSOR_CONFIG = 'e-edition-uploaded-event-processor-config',

  /**
   * @deprecated The associated cron has been removed and this flag is no longer used.
   */
  E_EDITION_VERIFICATION_EVENT_PROCESSOR_CONFIG = 'e-edition-verification-event-processor-config',

  /**
   * Can be used to override the default processor config for the processNoticeReconcileRunsEventsCron
   */
  NOTICE_RECONCILE_RUNS_EVENT_PROCESSOR_CONFIG = 'notice-reconcile-runs-event-processor-config',

  /**
   * Can be used to override the default processor config for the processRunVerificationInitiatedEventsCron
   */
  RUN_VERIFICATION_EVENT_PROCESSOR_CONFIG = 'run-verification-event-processor-config',

  /**
   * When turned on, use the new events-based apprach of sending notice related notifications
   */
  HANDLE_NOTIFICATIONS_WITH_EVENTS = 'handle_notifications_with_events',

  /**
   * Enable attachment of PDF of unpaid invoices report to each new invoice notification
   */
  ENABLE_ADD_OF_UNPAID_INVOICES_REPORT_TO_NEW_INVOICE_NOTIFICATIONS = 'enable-add-of-unpaid-invoices-report-to-new-invoice-notifications',

  /**
   * When turned on, all Elastic requests will be performed with the new Elastic node client @elastic/enterprise-search
   */
  USE_NEW_ELASTIC_CLIENT = 'use-new-elastic-client',

  /**
   * Enable a kill switch on running elastic consistency syncs
   */
  ENABLE_ELASTIC_EVENTUAL_CONSISTENCY = 'enable-elastic-eventual-consistency',

  /**
   * Cache balance transaction data on our payout records to speed up access
   */
  ENABLE_CACHE_BALANCE_TRANSACTIONS = 'enable-cache-balance-transactions',

  /**
   * @deprecated
   * In Cloudinary, width transformation will error for images greater than 100 megapixels. When on transformations
   * are allowed but do not contain a width property.
   */
  SKIP_CLOUDINARY_LARGE_FILE_WIDTH_TRANSFORMATION = 'skip-cloudinary-large-file-width-transformation',

  /**
   * Enable Job ID Caching for InDesign requests
   * */
  USE_INDESIGN_PAYLOAD_HASH_AS_JOB_ID = 'use-indesign-payload-hash-as-job-id',

  /**
   * When enabled, all reps only features will be visible to reps in app
   */
  ENABLE_REPS_ONLY_FEATURES = 'enable-reps-only-features',

  /**
   * @deprecated
   * Enable Display Ad Submitted Slack notifications
   * */
  ENABLE_DISPLAY_AD_SUBMITTED_NOTIFICATIONS = 'enable-display-ad-submitted-notifications',

  /**
   * @deprecated
   * Enable Display Ad Slack post witout formatting notifications
   * */
  ENABLE_DISPLAY_AD_POST_WITHOUT_FORMATTING_NOTIFICATIONS = 'enable-display-ad-post-without-formatting-notifications',

  /**
   * When enabled, payments through Elavon for Lee papers using Adpoint will use a surcharged-enabled terminal (which will apply surcharges for CCs that accept surcharges)
   */
  ENABLE_LEE_ADPOINT_SURCHARGES_IN_ELAVON = 'enable-lee-adpoint-surcharges-in-elavon',

  /**
   * Unclear if borders should be added to large display ads. The current workaround is to upload large display ads via Column Admin.
   * Uploading files this way does not add a border and so far no complaints from publishers. THis will default to false but can be turn on to allow borders.
   */
  ENABLE_CLOUDINARY_BORDER_TRANSFORMATION = 'enable-cloudinary-border-transformation',

  /**
   * Set max number of affidavits that can go in a single auto-affidavit signing batch
   */
  AUTO_AFFIDAVIT_SIGNING_BATCH_SIZE = 'AUTO_AFFIDAVIT_SIGNING_BATCH_SIZE',

  /**
   * @deprecated Allow users to change email address from the account settings
   */
  ALLOW_USERS_TO_CHANGE_EMAIL = 'allow-users-to-change-email',

  /**
   * Allows users to edit obituaries & classifieds
   */
  ENABLE_ORDER_EDIT_FLOW = 'enable-order-edit-flow',

  /**
   * @deprecated Generalized Wick specific functions for Affinity X. If the general fns don't workm as expected switch back to Wick specific
   */
  USE_GENERALIZED_AX_FUNCTIONS = 'use-generalized-ax-functions',

  /**
   * Allows users to select multiple invoices and pay them with a single stripe charge.
   */
  SHOW_BULK_PAYMENT_IN_INVOICES_TABLE = 'show-bulk-payment-in-invoices-table',

  /**
   * Can be used to override the default processor config for the processBulkPaymentReceiptReadyEventsCron
   */
  BULK_PAYMENT_RECEIPT_READY_PROCESSOR_CONFIG = 'bulk-payment-receipt-ready-processor-config',

  /**
   * Use Column's CDN and image transformer
   */
  ENABLE_COLUMN_CDN = 'enable-column-cdn',

  /**
   * Use Column's CDN in Pagination builder request
   */
  ENABLE_COLUMN_CDN_IN_DOWNLOAD_PAGINATION_BLOCK = 'enable-column-cdn-in-download-pagination-block',

  /**
   * Allow pdf to upload as an image when images in liners is enabled in newspaper
   */
  ALLOW_PDF_TO_IMAGE_IN_LINERS = 'allow-pdf-to-image-in-liners',

  /**
   * When enabled the notice category filter will be shown in the notices table
   */
  ENABLE_NOTICE_CATEGORY_FILTER = 'enable-notice-category-filter',

  /**
   * Show multiple publishing mediums in order settings for enabled organizations
   */
  ENABLE_PUBLISHING_MEDIUMS_SETTINGS = 'enable-publishing-mediums-settings',

  /**
   * Override emails from a Column user with an email-specific string set in LD.
   */
  INBOX_AUTOMATION_EMAIL_OVERRIDE = 'inbox-automation-email-override',

  /**
   * When enabled the adjudication area section will show up in the settings page
   */
  ENABLE_ADJUDICATION_AREA_ORGANIZATION_SETTINGS = 'adjudication-areas-organization-settings',

  /**
   * Enables coupon functionality for orders
   */
  ENABLE_COUPONS = 'enable-coupons',

  /**
   * @deprecated
   * When enabled, shows the more feature-dense of the pagination table (what we currently show
   * for public notices) for obits & classifieds.
   */
  ENABLE_ADVANCED_PAGINATION = 'enable-advanced-pagination',

  /**
   * When enabled the placement flow will display state, counties and cities to find publishers
   */
  ENABLE_ADJUDICATION_AREA_PLACEMENT_FLOW = 'adjudication-areas-placement-flow',

  /**
   * Enables uploading of zero-order publication issue manifest to the publisher via FTP from pagination
   */
  ENABLE_ZERO_ORDER_PUBLICATION_ISSUE_MANIFEST_UPLOAD_TO_PUBS_FROM_PAGINATION = 'enable-zero-order-publication-issue-manifest-upload-to-pubs-from-pagination',

  /**
   * Multipage PDFs downloaded in bulk download and notice details page instead of smashed versions
   */
  ENABLE_MULTIPAGE_PDF_DOWNLOADS = 'enable-multipage-pdf-downloads',

  /**
   *
   */
  ENABLE_ADJUDICATION_AREA_PLACEMENT_FILTER_CITY = 'adjudication-areas-placement-filter-city',

  /**
   * When enabled, obits/classifieds will have the option to limit the number of words in an ad
   */
  ENABLE_CATEGORY_WORD_COUNT = 'obits-classified-category-word-count',

  /**
   * @deprecated
   * Serve PDF file as is (not through Cloudinary) when clicking the PDF download on the notice page
   */
  DISABLE_PDF_RASTERIZATION_ON_NOTICE_DETAILS_PAGE = 'disable-pdf-rasterization-on-notice-details-page',

  /**
   * Show an activity log on the order detail page
   */
  ENABLE_ORDER_DETAIL_ACTIVITY_LOG = 'enable-order-detail-activity-log',

  /**
   * Use font service instead of bundled fonts for serving fonts for obits/classifeds
   */
  USE_FONT_SERVICE = 'use-font-service',

  /**
   * When enabled, allows customers with non-upfront billing terms to submit orders without payment.
   */
  ALLOW_ORDERS_WITHOUT_UPFRONT_PAYMENT = 'allow-orders-without-upfront-payment',

  /**
   * Enable the Copilot editor
   */
  ENABLE_COPILOT_EDITOR = 'enable-copilot-editor',

  /**
   * Enable the Pintura image editor
   */
  ENABLE_ADVANCED_IMAGE_EDITING = 'enable-advanced-image-editing',

  /**
   * Publisher can mark the invoice paid for obits and classifieds
   */
  ENABLE_MARK_INVOICE_PAID_FOR_OBITS_AND_CLASSIFIEDS = 'enable-mark-invoice-paid-for-obits-and-classifieds',

  /**
   * When enabled, Users can create job ads using placement flow
   */
  ENABLE_JOB_CATEGORY_IN_PLACEMENT_FLOW = 'job-category-in-placement-flow',

  /**
   * Enable a faster version of the publisher select step in the placement flow
   */
  ENABLE_FAST_PUBLISHER_SELECTION = 'enable-fast-publisher-selection',

  /**
   * Send a monthly report of unpaid order invoices to the organization or user
   */
  ENABLE_MONTHLY_STATEMENTS_FOR_ORDERS = 'enable-monthly-statements-for-orders',

  /**
   * Enable Puppeteer rendering caching
   */
  ENABLE_PUPPETEER_RENDERING_CACHING = 'enable-puppeteer-rendering-caching',

  /**
   * Allow funeral home directors to self register
   */
  ENABLE_FUNERAL_HOME_SELF_REGISTRATION = 'enable-funeral-home-self-registration',

  /**
   * Allow drafts to be deleted on the order table
   */
  ENABLE_DRAFT_ORDER_DELETION = 'enable-draft-order-deletion',

  /**
   * Hide orders that came from outside of Column in the orders table
   */
  HIDE_EXTERNAL_COLUMN_IN_ORDERS_TABLE = 'hide-external-column-in-orders-table',

  /**
   * Enable additional links on the display site
   */
  ENABLE_ADDITIONAL_LINKS_ON_DISPLAY_SITE = 'enable-additional-links-on-display-site',

  /**
   * Enable using concept of period-based rates into our billing for notices, obits and classifieds
   */
  ENABLE_PERIOD_BASED_ORDER_PRICING = 'enable-period-based-order-pricing',

  /**
   * Show a preview tab on the order detail drawer for obits and classifieds
   */
  ENABLE_PREVIEW_TAB_ON_ORDER_DETAIL_DRAWER = 'enable-preview-tab-on-order-detail-drawer',

  /**
   * Show a "universal download" button on the pagination table
   */
  ENABLE_UNIVERSAL_DOWNLOAD_BUTTON_ON_PAGINATION_TABLE = 'enable-universal-download-button-on-pagination-table',

  /**
   * Enables publisher fee splits for orders (obits and classifieds)
   */
  ENABLE_FEE_SPLITS_FOR_ORDERS = 'enable-fee-splits-for-orders',

  /**
   * Emergency kill switch to stop order transfers from initiating in code
   * Temporary flag to support rollout of new order transfer mechanism
   * Should be deleted by Feb 2025
   */
  DISABLE_ORDER_TRANSFERS = 'disable-order-transfers',

  /**
   * Shift the order madlib questions to filing type selection step
   */
  SHIFT_ORDER_MADLIB_QUESTIONS_TO_FILING_TYPE_SELECTION = 'shift-order-madlib-questions-to-filing-type-selection',

  /**
   * Disable address verification for a specific user (useful for allowing mailing when USPS has not verified the address yet)
   */
  DISABLE_ADDRESS_VERIFICATION = 'disable-address-verification',

  /**
   * Enable / disable embeddings for orders in elasticsearch
   */
  ENABLE_SEARCHABLE_ORDER_EMBEDDINGS = 'enable-searchable-order-embeddings',

  /**
   * Auto fill design notes with font name and size
   */
  ADD_FONT_SPECS_TO_DESIGN_NOTES = 'add-font-specs-to-design-note',

  /**
   * Enable pre-filling madlib data for death notices
   */
  ENABLE_PRE_FILLING_MADLIB_DATA_FOR_DEATH_NOTICES = 'enable-pre-filling-madlib-data-for-death-notices',

  /**
   * Allows registered individual users to place obituaries
   */
  ENABLE_INDIVIDUAL_OBITUARY_PLACEMENT = 'enable-individual-obituary-placement',

  /**
   * Enable the ability to filter ads by publishing medium on the display site
   */
  ENABLE_MEDIUM_RESTRICTION_ON_DISPLAY_SITES = 'enable-medium-restriction-on-display-sites',

  /**
   * @deprecated
   */
  ENABLE_LATE2LIEN_FIRST_PARTY_NOTICE_AUTO_PLACEMENT = 'enable-late2lien-first-party-notice-auto-placement',

  /**
   * Enable the updated order image editor to support more flexible image settings
   */
  ENABLE_NEW_ORDER_IMAGE_EDITOR = 'enable-new-order-image-editor',

  /**
   * Enable new AX order number generation
   */
  ENABLE_NEW_AX_ORDER_NUMBER_GENERATION = 'enable-new-ax-order-number-generation',

  /**
   * Enable controlling the Column public notice display site directly in the app
   */
  ENABLE_COLUMN_PUBLIC_NOTICE_DISPLAY_SITE_CONTROL = 'expose-column-public-notice-display-site-settings',
  /**
   * Enable loading and displaying media library selection in the order placement flow
   */
  ENABLE_MEDIA_LIBRARY_IN_PLACEMENT = 'enable-media-library-in-placement',

  /**
   * Enable the ability to optionally apply tax to products
   */
  ENABLE_PRODUCT_TAX_SETTINGS = 'enable-product-tax-settings',

  /*
   * Enables automated syncing of applicable orders to Jobcase
   */
  ENABLE_JOBCASE_SYNC = 'enable-jobcase-sync',

  /**
   * Enables configuration and payment for Jobcase upsell in orders
   */
  ENABLE_JOBCASE_UPSELL = 'enable-jobcase-upsell',

  /**
   * Use AffinityX API
   */
  USE_AFFINITYX_API = 'use-affinityx-api',

  /**
   * Enable automatic confirmation when auto invoicing is enabled
   */
  ENABLE_AUTO_CONFIRM_ON_AUTO_INVOICE = 'enable-auto-confirm-on-auto-invoice',

  /**
   * Persistent kill switch to disable inbox automation trigger events
   */
  DISABLE_INBOX_AUTOMATION_TRIGGER_EVENTS = 'disable-inbox-automation-trigger-events',

  /**
   * @deprecated Use general automation for express placement
   */
  USE_GENERAL_AUTOMATION_FOR_EXPRESS_PLACEMENT = 'use-general-automation-for-express-placement',

  /**
   * @deprecated Use general automation for express snooze
   */
  USE_GENERAL_AUTOMATION_FOR_EXPRESS_SNOOZE = 'use-general-automation-for-express-snooze',

  /**
   * Use general automation for creating express notices from proofs
   */
  USE_GENERAL_AUTOMATION_FOR_CREATE_NOTICE = 'use-general-automation-for-create-notice',

  /**
   * Use general automation for creating express invoices from Front
   */
  USE_GENERAL_AUTOMATION_FOR_CREATE_INVOICE = 'use-general-automation-for-create-invoice',

  /**
   * Use general automation for late2lien first party placement
   */
  USE_GENERAL_AUTOMATION_FOR_FIRST_PARTY_PLACEMENT = 'use-general-automation-for-first-party-placement',

  /**
   * Use general automation for initiate proof review Front macro
   */
  USE_GENERAL_AUTOMATION_FOR_INITIATE_PROOF_REVIEW = 'use-general-automation-for-initiate-proof-review-front-macro',

  /**
   * Use general automation for bump express placement email
   */
  USE_GENERAL_AUTOMATION_FOR_BUMP_EXPRESS_PLACEMENT_EMAIL = 'use-general-automation-for-bump-express-placement-email',

  /**
   * Use general automation for placing notices in publisher portals
   */
  USE_GENERAL_AUTOMATION_FOR_INITIATE_PORTAL_NOTICE_PLACEMENT = 'use-general-automation-for-initiate-portal-notice-placement',

  /**
   * Enable the packages feature
   */
  ENABLE_PACKAGES = 'enable-packages',

  /**
   * Enable the placements tab for express email conversations
   */
  ENABLE_PLACEMENTS_TAB = 'enable-placements-tab',

  /*
   * Enable Lee auto google drive upload
   */
  ENABLE_LEE_AUTO_GOOGLE_DRIVE_UPLOAD = 'enable-lee-auto-google-drive-upload',

  /**
   * Enable publication issue section sync to Elastic
   */
  ENABLE_PUBLICATION_ISSUE_SECTION_SYNC_TO_ELASTIC = 'enable-publication-issue-section-sync-to-elastic',

  /**
   * Enable new elastic-based pagination issue section table
   */
  ENABLE_NEW_ELASTIC_PAGINATION_ISSUE_SECTION_TABLE = 'use-new-elastic-pagination-issue-section-table',

  /**
   * Enable new pagination settings
   */
  ENABLE_NEW_PAGINATION_SETTINGS = 'enable-new-pagination-settings',

  /**
   * Enable NYPA statutory rate pricing
   */
  ENABLE_NYPA_STATUTORY_RATE_PRICING = 'enable-nypa-statutory-rate-pricing',

  /**
   * Run auto pagination on publication issues when they change status
   */
  ENABLE_AUTO_PAGINATION_ON_PUBLICATION_ISSUE_STATUS_CHANGE = 'enable-auto-pagination-on-publication-issue-status-change',

  /**
   * Enable auto-uploading to in-app admin builder
   */
  ENABLE_AUTO_UPLOADING_TO_IN_APP_ADMIN_BUILDER = 'enable-auto-uploading-to-in-app-admin-builder',

  /**
   * @deprecated
   */
  ENABLE_FAST_VERIFICATION_QUEUE = 'enable-fast-verification-queue',

  /**
   *  The colorspace for display ad pdfs: RGB or CMYK
   */
  COLORSPACE_FOR_DISPLAY_PDF_EXPORTS = 'colorspace-for-display-pdf-exports',

  /**
   * Enable auto-processing of oncall issues via our backend agent
   */
  ENABLE_ONCALL_AUTO_PROCESS = 'enable-oncall-auto-process',

  /**
   * Enable processing mutli publisher packages in placement
   */
  ENABLE_MULTI_PUBLISHER_PACKAGES = 'enable-multi-publisher-packages',

  /**
   * TEMPORARY FLAG
   * Enable UI in admin for modifying publisher transfers trigger -- will be removed after migration is run on publishers to migrate publisher transfer triggers to new format
   */
  ENABLE_PUBLISHER_TRANSFERS_TRIGGER_UI_IN_ADMIN = 'enable-publisher-transfers-trigger-ui-in-admin'
}

export interface LaunchDarklyContext {
  getBooleanFeatureFlag(
    flag: LaunchDarklyFlags,
    options?: FeatureFlagOptions<boolean>
  ): Promise<boolean>;
}
