import { useState } from 'react';

import { Form } from 'lib/components/Form';
import { ColumnButton } from 'lib/components/ColumnButton';
import { TextField } from 'lib/components/TextField';
import { ESnapshotExists, EOrganization } from 'lib/types';
import { Autocomplete } from 'lib/components/Autocomplete';
import { State } from 'lib/enums';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { useAppDispatch } from 'redux/hooks';
import ToastActions from 'redux/toast';

import { removeUndefinedFields } from 'lib/helpers';
import SettingsHeader from '../SettingsHeader';
import OrganizationSearchSettingToggle from '../OrganizationSearchSettingToggle';
import SettingsPage from '../SettingsPage';

type OrganizationAddressFormProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
};

export default function OrganizationAddressForm({
  activeOrganization
}: OrganizationAddressFormProps) {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState(activeOrganization.data());
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await activeOrganization.ref.update(
        removeUndefinedFields({
          ...formData
        })
      );
      dispatch(
        ToastActions.toastSuccess({
          headerText: 'Saved',
          bodyText: 'Organization info updated.'
        })
      );
    } catch (err) {
      logAndCaptureException(
        ColumnService.SETTINGS_MANAGEMENT,
        err,
        'Error updating organization info',
        {
          organizationId: activeOrganization.id
        }
      );
      dispatch(
        ToastActions.toastError({
          headerText: 'Error updating organization info',
          bodyText: 'An error occurred while updating organization info.'
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <SettingsPage>
      <Form onSubmit={handleSubmit}>
        <SettingsHeader
          header="Government Info"
          description="Tell us a bit more about your organization."
        >
          <ColumnButton
            loading={loading}
            buttonText="Save Changes"
            type="submit"
            primary
          />
        </SettingsHeader>
        <div className="px-4 py-5 sm:p-6 space-y-4">
          <TextField
            id="organization-name"
            labelText="Organization name"
            placeholder="Organization name"
            required
            value={formData.name}
            onChange={newValue => setFormData({ ...formData, name: newValue })}
          />
          <TextField
            id="address-line-1"
            labelText="Address line 1"
            placeholder="Address line 1"
            required
            value={formData.address}
            onChange={newValue =>
              setFormData({ ...formData, address: newValue })
            }
          />
          <TextField
            id="address-line-2"
            labelText="Address line 2"
            placeholder="Address line 2"
            value={formData.addressLine2}
            onChange={newValue =>
              setFormData({ ...formData, addressLine2: newValue })
            }
          />
          <TextField
            id="city"
            labelText="City"
            placeholder="City"
            required
            value={formData.city}
            onChange={newValue => setFormData({ ...formData, city: newValue })}
          />
          <Autocomplete
            id="state"
            labelText="State"
            placeholder="State"
            required
            value={formData.state?.toString() || ''}
            options={State.items().map(item => ({
              value: item.value.toString(),
              label: item.label
            }))}
            onChange={newValue =>
              setFormData({ ...formData, state: Number(newValue) })
            }
          />
          <TextField
            id="zipCode"
            labelText="Zip code"
            placeholder="Zip code"
            type="postal-code"
            required
            value={formData.zipCode}
            onChange={newValue =>
              setFormData({ ...formData, zipCode: newValue })
            }
          />
          <TextField
            id="phone"
            labelText="Phone"
            placeholder="Phone"
            type="tel"
            required
            value={formData.phone}
            onChange={newValue => setFormData({ ...formData, phone: newValue })}
          />
          <OrganizationSearchSettingToggle />
        </div>
      </Form>
    </SettingsPage>
  );
}
